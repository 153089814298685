@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');

header {
    background-color: #433434;
    height: 500px; }

.feat-img {
    object-fit: cover;
    opacity: .55; }

.title {
    position: relative;
    max-width: 900px;
    margin: -220px auto 0;
    color: white;
    background-color: rgba(0,0,0,.55);
    font-family: "Century Gothic",Futura,Arial, sans-serif;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 1px 10px;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -1.6px; }

.news-content {
    font-size: large;
    color: darkslategrey;
    text-align: justify;
    font-family: 'PT Serif', serif;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 50px; }

img {
    width: 100%;
    height: 100%; }

strong {
    font-weight: 900; }

h1 {
    margin-top: 0;
    margin-bottom: 10px; }

.caption {
    font-size: 14px; }

.actioncall {
    font-family: Arial;
    margin: 100px 30px 50px;
    background-color: #0D567F; }


@media screen and (max-width: 768px) {
    header {
        height: 300px; }

    .title {
        font-size: 30px;
        margin-top: -150px; }

    .news-content {
        margin: 10px 20px;
        font-size: large; } }
