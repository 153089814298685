#myVideo {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 600px;
    object-fit: cover;
    z-index:  -1; }

.intro-overlay {
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background: linear-gradient(to top, #3c89c3, rgba(60, 137, 195, 0.3), rgba(60, 137, 195, 0.25), rgba(60, 137, 195, 0.1), rgba(60, 137, 195, 0.0));
    opacity: 1; }



#slogan {
    color: white;
    font-size: 30px;
    top: 320px;
    position: absolute;
    z-index: 900;
    line-height: 1.42857143; }


#leftpart {
    text-align: right; }


#rightpart {
    text-align: left;
    margin-top: 50px;
    padding-left: 0;
    overflow: hidden;
    height: 30px;
    line-height: 30px;
    font-weight: 900;
    font-size: 30px; }


button {
    position: absolute;
    color: white;
    background-color: transparent;
    border-color: white;
    border-radius: 8%;
    bottom: 20%;
    right: 10%;
    z-index: 100;
    padding: 20px;
    font-family: Montserrat; }


.rotate {
    display: inline-block;
    position: relative;
    white-space: nowrap;

    /*animation*/
    -webkit-animation: move 12s;
    -moz-animation: move 12s;
    -ms-animation: move 12s;
    -o-animation: move 12s;
    animation: move 12s;
    /*animation-iteration-count*/
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    /*animation-delay*/
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    -ms-animation-delay: 3s;
    -o-animation-delay: 3s;
    animation-delay: 3s; }


@keyframes move {
    0% {
        top: 0px; }

    25% {
        top: -30px; }

    50% {
        top: -60px; }

    75% {
        top: -90px; }

    100% {
        top: -120px; } }


@-webkit-keyframes move {
    0% {
        top: 0px; }

    25% {
        top: -30px; }

    50% {
        top: -60px; }

    75% {
        top: -90px; }

    100% {
        top: -120px; } }



@-moz-keyframes move {
    0% {
        top: 0px; }

    25% {
        top: -30px; }

    50% {
        top: -60px; }

    75% {
        top: -90px; }

    100% {
        top: -120px; } }



@-o-keyframes move {
    0% {
        top: 0px; }

    25% {
        top: -30px; }

    50% {
        top: -60px; }

    75% {
        top: -90px; }

    100% {
        top: -120px; } }



@keyframes move {
    0% {
        top: 0px; }

    25% {
        top: -30px; }

    50% {
        top: -60px; }

    75% {
        top: -90px; }

    100% {
        top: -120px; } }

@media screen and (max-width: 630px) {
    #leftpart {
        transform: scale(0.55); }

    #rightpart {
        transform: scale(0.55);
        margin-top: 40px; } }

@media screen and (max-width: 400px) {
    #leftpart {
        transform: scale(0.5); }

    #rightpart {
        transform: scale(0.5);
        margin-top: 40px; } }

@media screen and (max-width: 350px) {
    #leftpart {
        transform: scale(0.45); }

    #rightpart {
        transform: scale(0.45);
        margin-top: 40px; } }
