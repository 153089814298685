.about-element {
  height: 120px; }

.about-element:hover {
  transition: transform 0.4s;
  transform: scale(1.1);
  -ms-transformm: scale(1.1); }


.about-element img {
  height: 75%;
  opacity: 0.8; }


