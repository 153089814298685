.section-contact {
  position: relative;
  margin-bottom: 80px; }


form {
  max-width: 450px;
  margin: 0 auto 100px;
  padding: 0 20px;

  input, textarea {
    border: 1.5px solid $accent;
    width: 100%;
    @include box-sizing(border-box);
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;

    &:focus {
      outline: none;
      border-color: $accent-light; } }

  textarea {
    height: 10em;
    resize: none; }

  .contact-info-group {
    border: 0;
    padding-left: 10%;

    label {
      width: 45%;
      float: left;
      @include box-sizing(border-box);

      &:nth-child(1) {
        padding-right: 10px; }

      &:nth-child(2) {
        padding-left: 10px; } } }

  label {
    display: block; }

  .submit-wrap {
    border: 0;
    padding-right: 0;
    text-align: right; }

  [type="submit"] {
    background: $accent;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    width: 33%;

    &:hover {
      background: $accent-light; } } }

@media (max-width: 541px) {
  .section-contact {
    width: 100%;
    margin-bottom: 10px; }

  form {
    margin: 0 auto 0;

    .contact-info-group {
      padding-left: 5%;

      label {
        width: 100%;

        &:nth-child(1) {
          padding-right: 0; }

        &:nth-child(2) {
          padding-left: 0;
          padding-top: 10px; } } }

    .submit-wrap {
      padding-left: 0; }

    [type="submit"] {
      width: 100%; } } }
