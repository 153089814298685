.video-box {
  background-color: rgb(60, 137, 195);
  color: white;
  padding: 3% 0 3%;
  margin-top: 5%;
  -webkit-transform: skewY(3deg);
  -moz-transform: skewY(3deg);
  -ms-transform: skewY(3deg);
  -o-transform: skewY(3deg);
  transform: skewY(3deg); }


.video-box > .wrapper {
  -webkit-transform: skewY(-3deg);
  -moz-transform: skewY(-3deg);
  -ms-transform: skewY(-3deg);
  -o-transform: skewY(-3deg);
  transform: skewY(-3deg); }

.highlight-coin {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: white;
  transition: transform .2s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto 0px; }

.highlight-coin:hover {
    transform: scale(1.5);
    -ms-transformm: scale(1.5); }

.popover-title {
  color: #252850; }

.popover-content {
  color: #252850;
  text-align: center; }

.popover {
    width: 400px; }

@media screen and (max-width: 768px) {
  .video-box {
    padding-top: 7%; }

  #video iframe {
    height: 135px;
    width: 255px; }

  .popover {
    width: 200px; } }

@media screen and (max-width: 1300px) {
  .video-box {
    margin-top: 10%; } }

@media screen and (max-width: 1200px) {
  .video-box {
    margin-top: 15%; } }

@media screen and (max-width: 1100px) {
  .video-box {
    margin-top: 20%; } }

@media screen and (max-width: 1000px) {
  .video-box {
    margin-top: 25%; } }

@media screen and (max-width: 800px) {
  .video-box {
    margin-top: 30%; } }

@media screen and (max-width: 600px) {
  .video-box {
    margin-top: 65%; } }

@media screen and (min-width: 1200px) {
  #proj-desc h3, #proj-desc p {
    text-align: left; } }



.to-fade-in {
    -webkit-animation-name: fadeInDown;
    -moz-animation-name: fadeInDown;
    -o-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-duration: 1s;
    animation-delay: 0.5s; }
