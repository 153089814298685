footer {
    width: 100%;
    height: 290px;
    background: #06569A;
    color: white; }

footer p {
    font-weight: lighter;
    margin-bottom: 0;
    font-size: 0.9rem;

    b {
        font-weight: 900; } }

.social {
    text-align: center;
    font-size: 4vh;
    height: 6vh;
    width: 6vh;
    color: white; }


@media screen and (max-width: 768px) {
    footer {
        height: 420px;
        padding-top: 15px; }

    .left-align {
        text-align: center; }

    .footer-social {
        text-align: center;
        margin-top: 50px; }

    .social {
        height: unset;
        width: 5vh;
        margin-top: 15px; } }
