#longnav {
    text-align: left;
    background-color: white;
    transition: all 0.2s; }


.nav-wrapper a {
    max-width: 400px;
    color: #0D567F;
    font-weight: bold; }

nav ul a:hover {
    background-color: rgba(13, 86, 127,0.3); }

#logo {
    float: left;
    width: 80%;
    transition: 0.3s all;
    padding-left: 1vh;
    margin-top: 8px;
    margin-bottom: 8px; }

@media screen and (max-width: 600px) {
    .nav-wrapper a {
        width: fit-content; }

    #logo {
        float: unset;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 65%; } }

#return-to-top {
    position: fixed;
    z-index: 500;
    bottom: 20px;
    background: #666666;
    right: 20px;
    width: 5vh;
    height: 5vh;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }


#return-to-top i {
    color: #fff;
    font-size: 3vh;
    margin-top: 15%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }


#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9); }


#return-to-top:hover i {
    color: #fff;
    top: 5px; }


#sharebar {
    color: #0D567F;
    margin-top: 100px;
    text-align: center; }

#sharebar h2 {
    font-size: 2.2vh; }

.mobile_social {
    color: #0D567F;
    transform: scale(1.8);
    margin: 0 25px 20px; }
