@import 'header';
@import 'footer';
@import 'team';
@import 'video_section';
@import 'about';
@import 'contact';
@import 'newsletter';
@import 'partner';
@import 'news_post';
@import 'news_carousel';
@import 'navigation';
