@import '1-tools/-tools-dir';

//------------------------------------------------
// Transition the image paths from the settings file to usable SASS variables
//$logo-path: 'logo'
// $animation:

//------------------------------------------------
// Set some colors as sass variables

$accent: #0A6486;
$accent-light: adjust-hue(saturate(lighten($accent, 11%), 12.6%), 14%);
$selectionColor: lighten($accent, 30%);

$textColor: #666;
$headlineColor: #333;
$bgcolor: white;
$documentBackgroundColor: #fff;

//@import '2-base/-base-dir'
@import '3-sections/-sections-dir';
