.partner_container {
	margin-top: 80px; }

.partner_container img {
	width: 10%;
	min-width: 110px; }


.partner_text {
	margin-top: 20px;
	margin-bottom: 40px;
	width: 35%;
	margin-left: auto;
	margin-right: auto;
	height: 0;
	opacity: 0;
	transition: height 1s, opacity 1s ease-in-out;
	-moz-transition: height 1s, opacity 1s ease-in-out;
	-webkit-transition: height 1s, opacity 1s ease-in-out;
	-ms-transition: height 1s, opacity 1s ease-in-out;
	-o-transition: height 1s, opacity 1s ease-in-out; }

.partner_container:hover .partner_text {
	height: 150px;
	opacity: 1;
	transition: height .5s, opacity .5s ease-in-out;
	-moz-transition: height .5s, opacity .5s ease-in-out;
	-webkit-transition: height .5s, opacity .5s ease-in-out;
	-ms-transition: height .5s, opacity .5s ease-in-out;
	-o-transition: height .5s, opacity .5s ease-in-out; }

#partner a {
	color: grey;
	margin-top: 30px; }


@media screen and (max-width: 600px) {
	#partner {
		margin-top: 100px; }

	.partner_text {
		width: 70%;
		margin-bottom: 100px; } }

