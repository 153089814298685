.news-carousel-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto; }

.flexslider {
    border: 0;
    border-radius: 10px; }

.flex-control-thumbs {
    margin: 0; }

.news-tumb-container {
    background-color: #433434;
    border-radius: 10px;

    a {
        text-decoration: none; } }

.news-tumb {
    opacity: .75;
    border-radius: 10px; }

.flex-caption {
    width: 96%;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0,0,0,.3);
    font-size: 14px;
    line-height: 18px; }

.flex-direction-nav {
    .flex-next, .flex-prev {
        height: 50px; }

    a:before {
        color: white; } }

.news-title-container {
    height: 0; }

.news-title {
    position: relative;
    bottom: 140px;
    left: 0;
    color: white;
    background-color: rgba(0,0,0,.55);
    display: inline;
    font-family: "Century Gothic",Futura,Arial,sans-serif;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 1px 10px;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -1px; }

@media screen and (max-width: 1400px) {
    .news-title {
        font-size: 25px; } }

@media screen and (max-width: 1400px) {
    .news-title {
        bottom: 120px;
        padding: 0; } }

@media screen and (min-width: 992px) {
    .news-carousel-container {
        width: 50%; } }

@media screen and (max-width:768px) {
    .news-carousel-container {
        width: 75%; } }


@media screen and (max-width:350px) {
    .news-carousel-container {
        width: 90%; } }

