.pop_up {
    bottom: 0;
    right: 70px;
    padding: 10px;
    background: white;
    border-radius: 5px;
    border: 1px solid $accent-light;
    width: 300px;
    height: 180px;
    position: fixed;
    transition: all 8s ease-in-out;
    text-align: center;
    display: none;
    z-index: 300;
    animation: 2s fadeIn; }

.pop_up button {
    position: absolute;
    top: -79px;
    right: 10px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    border: none;
    background-color: transparent;
    overflow: hidden; }

#p1 {
    padding-top: 35px;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: $accent; }

.addthis_inline_share_toolbox {
    transform: scale(0.6); }

