.carousel {
  height: unset; }

.carousel-control.right, .carousel-control.left {
  background-image: none;
  color: rgb(60, 137, 195); }

.carousel-indicators li {
  border-color: rgb(60, 137, 195); }

.carousel-indicators li.active {
  background-color: rgb(60, 137, 195); }

.item h4 {
  font-weight: 400;
  font-style: italic; }

.item span {
  font-style: normal; }

.item img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%; }

.carousel-indicators {
  position: unset;
  left: unset;
  margin-left: unset;
  width: 100%; }

.team-info {
  padding-right: 12%;
  padding-left: 12%; }

@media screen and ( min-width: 768px) {
  .item img {
    width: 35%; } }

@media screen and ( min-width: 960px) {
  .item img {
    width: 40%;
    margin-right: 10%; }

  .name {
    text-align: center;
    margin-left: 40%; }

  .founder, .position {
    margin-left: -10%; }


  .team-info {
    padding-left: 10%;
    padding-right: 16%;
    padding-top: 4%; }

  .team-info h6 {
    text-align: justify;
    text-align-last: left; } }




